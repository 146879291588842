import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { FaCaretRight } from 'react-icons/fa';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LocationItem from '../components/location-item';

const Hours = () => (
  <div className="pt-20 pb-12 text-white flex items-center location-purple-gradient-cloud md:pt-14 md:pb-12 md:-mt-32 md:w-9/12 md:ml-auto md:-mr-24 lg:pt-20 5xl:pt-16 5xl:-mb-10">
    <h3 className="pl-10 font-header leading-none w-1/2 text-right text-2xl md:text-center md:pb-2 lg:text-3xl lg:ml-24">
      Horarios
      <br />
      de atención
    </h3>
    <div className="w-1/2 pl-6 text-sm lg:mr-24">
      <div className="font-header uppercase font-bold">Lunes a Viernes</div>
      <div>11h00 - 20h00</div>
    </div>
  </div>
);
const HoursMobile = () => (
  <div className="md:hidden">
    <Hours />
  </div>
);
const HoursDesktop = () => (
  <div className="container mx-auto hidden md:block md:mt-24">
    <Hours />
  </div>
);

const OurLocationsPage = () => {
  const data = useStaticQuery(graphql`
    query OurLocationsImages {
      ceibos: file(relativePath: { eq: "los-ceibos.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
        }
      }
      costa: file(relativePath: { eq: "via-a-la-costa.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
        }
      }
      colon: file(relativePath: { eq: "ciudad-colon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
        }
      }
      samborondon: file(relativePath: { eq: "samborondon-entre-rios.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
        }
      }
      daule: file(relativePath: { eq: "daule-via-la-aurora.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
        }
      }
      bus: file(relativePath: { eq: "expreso.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      busDriver: file(relativePath: { eq: "expreso-chofer.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
        }
      }
      busInterior: file(relativePath: { eq: "expreso-interior.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="¿Conoces nuestras ubicaciones?" />
      <section className="overflow-x-hidden">
        <div className="container mx-auto">
          <h2 className="flex items-start justify-center font-header leading-none text-purple-500 text-3xl md:max-w-md md:ml-0 md:mr-auto">
            <FaCaretRight className="w-10 h-10" />
            <span className="w-8/12 lg:w-72">
              ¿Conoces nuestras <span className="uppercase">ubicaciones</span>?
            </span>
          </h2>
          <ul className="flex flex-col md:flex-row md:flex-wrap md:items-center">
            <li className="my-3 w-full md:w-1/2 md:my-0">
              <LocationItem
                icon={data.ceibos.childImageSharp.gatsbyImageData}
                title="Los Ceibos"
                location="Av.Principal Los Ceibos Frente a Induauto"
                phoneLink="+59342001025"
                phone="04-2001025"
                mobileLink="+593989777632"
                mobile="0989777632"
              />
            </li>
            <li className="my-3 w-full md:w-1/2 md:my-0">
              <LocationItem
                icon={data.costa.childImageSharp.gatsbyImageData}
                title="Vía a La Costa"
                location="C.C. Blue Coast local 33-34 Planta Alta"
                phoneLink="+59342877219"
                phone="04-2877210"
                mobileLink="+593969473844"
                mobile="0969473844"
              />
            </li>
            <li className="my-3 w-full md:w-1/2 md:my-0">
              <LocationItem
                icon={data.samborondon.childImageSharp.gatsbyImageData}
                title="Samborondón Entre Ríos"
                location="Km 1,5 vía Samborondón, C.C. Prema, planta alta. Detrás de banco Pacífico, junto al Riocentro Entre Ríos."
                phoneLink="+59346042072"
                phone="04-6042072"
                mobileLink="+593988848210"
                mobile="0988848210"
              />
            </li>
            <li className="my-3 w-full md:w-1/2 md:my-0">
              <LocationItem
                icon={data.daule.childImageSharp.gatsbyImageData}
                title="Daule Vía La Aurora"
                location="Avalón Plaza II, local 3"
                phoneLink="+59344507335"
                phone="04-4507335"
                mobileLink="+593986349114"
                mobile="0986349114"
              />
            </li>
          </ul>
        </div>
        <HoursMobile />
        <HoursDesktop />
      </section>
    </Layout>
  );
};

export default OurLocationsPage;
