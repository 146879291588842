import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FaHandPointer, FaPhone } from 'react-icons/fa';

const LocationItem = ({
  icon,
  location,
  mobileLink,
  mobile,
  phoneLink,
  phone,
  title,
}) => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-1/2 max-w-7xs m-3">
        <GatsbyImage image={icon} alt={title} />
      </div>
      <div className="w-1/2">
        <h3 className="font-header leading-none text-purple-500 text-2xl">
          {title}
        </h3>
        <div className="text-gray-500 font-light">{location}</div>
        <div className="text-gray-500 font-light text-sm items-center">
          {phoneLink && (
            <div>
              <a
                className="cursor-pointer text-gray-400 font-bold underline flex items-center"
                href={`tel:${phoneLink}`}
              >
                <FaPhone className="mr-1" />
                Llamar {phone}
              </a>
            </div>
          )}
          <div>
            <a
              className="cursor-pointer text-gray-400 font-bold underline flex items-center"
              href={`tel:${mobileLink}`}
            >
              <FaHandPointer className="mr-1" />
              Llamar {mobile}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

LocationItem.propTypes = {
  icon: PropTypes.shape({ publicURL: PropTypes.string }).isRequired,
  location: PropTypes.string.isRequired,
  mobileLink: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  phoneLink: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default LocationItem;
